import React, { useEffect } from 'react';

interface Props {
  title?: string;
  shouldSetRawTitle?: boolean;
  children: React.ReactNode;
}
export const BaseLayout: React.VFC<Props> = ({
  title,
  shouldSetRawTitle,
  children,
}) => {
  useEffect(() => {
    if (!title) {
      document.title = 'Balloon';
      return;
    }
    document.title = shouldSetRawTitle ? title : `${title} / Balloon`;
  }, [shouldSetRawTitle, title]);
  return <>{children}</>;
};
