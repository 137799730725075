import axios from 'axios';
import { requestGet, requestPut } from 'util/apiAxios';
import { CustomApiError } from 'util/error';

export interface JoinedTeam {
  teamId: string;
  workspaceId: string;
  role: 'OWNER' | 'ADMIN' | 'MEMBER';
}

export interface User {
  id: string;
  name: string;
  email: string;
  joinedTeams: JoinedTeam[];
  privateWorkspaceId: string;
  createdAt: Date;
  updatedAt: Date;
}

type UserResponse = Modify<
  User,
  {
    createdAt: string;
    updatedAt: string;
  }
>;

const convertTimestamps = (data: UserResponse): User => ({
  ...data,
  createdAt: new Date(data.createdAt),
  updatedAt: new Date(data.updatedAt),
});

export const getUser = (userId: string): Promise<User> => {
  return requestGet<UserResponse>(`/api/users/${userId}`).then((res) =>
    convertTimestamps(res.data),
  );
};

type UserRequest = Pick<User, 'name'>;
export const updateUser = (
  userId: string,
  user: UserRequest,
): Promise<User> => {
  return requestPut<UserResponse, UserRequest>(
    `/api/users/${userId}`,
    user,
  ).then((res) => convertTimestamps(res.data));
};

type UserEmailRequest = Pick<User, 'email'>;
export class EmailAlreadyInUseError extends CustomApiError {}
export const changeUserEmail = (
  userId: string,
  email: string,
): Promise<User> => {
  return requestPut<UserResponse, UserEmailRequest>(
    `/api/users/${userId}/email`,
    { email },
  )
    .then((res) => convertTimestamps(res.data))
    .catch((err) => {
      if (
        axios.isAxiosError(err) &&
        err.response?.data?.errorCode === 'EMAIL_ALREADY_IN_USE'
      ) {
        throw new EmailAlreadyInUseError();
      }
      throw err;
    });
};
