import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors } from 'util/theme';
import { SnackBar } from 'components/common/SnackBar';

type SnackBarType = 'INFO' | 'DONE' | 'ERROR';

interface SnackBarContextValue {
  showSnackBar: (type: SnackBarType, text: string) => void;
}

export const SnackBarContext = createContext<SnackBarContextValue>({
  showSnackBar: () => void 0,
});

const PORTAL_ELEMENT_ID = 'portal-snack-bar';

interface SnackBarValue {
  id: number;
  icon: IconProp;
  text: string;
  color: string;
}

interface Props {
  children: React.ReactNode;
}
export const SnackBarContextProvider: React.VFC<Props> = ({ children }) => {
  const [snackBars, setSnackBars] = useState<SnackBarValue[]>([]);
  const nextId = useRef(0);

  const showSnackBar = useCallback((type: SnackBarType, text: string) => {
    setSnackBars((prev) => [
      ...prev,
      {
        id: nextId.current++,
        text,
        icon:
          type === 'INFO'
            ? faInfoCircle
            : type === 'DONE'
            ? faCheck
            : faExclamationCircle,
        color:
          type === 'INFO'
            ? colors.blue[500]
            : type === 'DONE'
            ? colors.blue[500]
            : colors.red[500],
      },
    ]);
  }, []);

  const hideSnackBar = useCallback((id: number) => {
    setSnackBars((prev) => prev.filter((v) => v.id !== id));
  }, []);

  const el = document.getElementById(PORTAL_ELEMENT_ID) as HTMLElement;
  return (
    <SnackBarContext.Provider
      value={useMemo(
        () => ({
          showSnackBar,
        }),
        [showSnackBar],
      )}
    >
      {createPortal(
        snackBars.map(({ id, icon, text, color }) => (
          <SnackBar
            color={color}
            duration={5000}
            onHide={() => hideSnackBar(id)}
            key={id}
          >
            <FontAwesomeIcon icon={icon} />
            {text}
          </SnackBar>
        )),
        el,
      )}
      {children}
    </SnackBarContext.Provider>
  );
};

export const useSnackBar = (): [(type: SnackBarType, text: string) => void] => {
  const { showSnackBar } = useContext(SnackBarContext);
  return [showSnackBar];
};
