import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ScrollRestoration from 'react-scroll-restoration';

import { LazyloadFallback } from './LazyloadFallback';
import { UniversalAnswerPage } from 'views/answer/UniversalAnswerPage/UniversalAnswerPage';
import { NotFoundPage } from 'views/NotFoundPage';

const AdminRoutes = React.lazy(() => import('./AdminRoutes'));

export const Router: React.VFC = () => (
  <Suspense fallback={<LazyloadFallback />}>
    <ScrollRestoration />
    <Switch>
      <Redirect exact path="/" to="/login" />
      <Route
        path="/answer/:publishedId"
        component={UniversalAnswerPage}
        exact
      />
      <AdminRoutes />
      <Route component={NotFoundPage} />
    </Switch>
  </Suspense>
);
