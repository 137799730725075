import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { generateButtonStyleProps } from 'util/style';
import { colors } from 'util/theme';
import { LoadingSpinner } from '../icons/LoadingSpinner';

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  color?: string;
  to?: string;
  href?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean;
  long?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export const RaisedButton: React.VFC<Props> = ({
  onClick,
  color,
  to,
  href,
  type,
  disabled,
  loading,
  long,
  children,
  className,
}) => {
  const buttonColorProps = generateButtonStyleProps(
    color || colors.primary[500],
  );

  if (to != null) {
    return (
      <StyledLink
        to={to}
        className={className}
        $long={!!long}
        {...buttonColorProps}
      >
        {children}
      </StyledLink>
    );
  }
  if (href != null) {
    return (
      <StyledAnchor
        href={href}
        className={className}
        $long={!!long}
        {...buttonColorProps}
      >
        {children}
      </StyledAnchor>
    );
  }
  return (
    <StyledButton
      type={type || 'button'}
      disabled={disabled}
      onClick={onClick}
      className={className}
      $long={!!long}
      {...buttonColorProps}
    >
      {children}
      {loading && (
        <LoadingOverlay {...buttonColorProps}>
          <LoadingSpinner size="1rem" color="#fff" />
        </LoadingOverlay>
      )}
    </StyledButton>
  );
};

const ButtonStyle = css<{
  $buttonColor: string;
  $shadowColor1: string;
  $shadowColor2: string;
  $long: boolean;
}>`
  position: relative;
  display: inline-flex;
  align-items: center;

  height: 2.5rem;
  padding: 0.5rem ${(p) => (p.$long ? '1.5rem' : '1rem')};
  border-radius: 0.5rem;
  background: ${(p) => p.$buttonColor};
  box-shadow: 0 1px 3px 0 ${(p) => p.$shadowColor1},
    0 1px 2px 0 ${(p) => p.$shadowColor2};

  transition: 0.3s;

  color: #fff;
  font-weight: 500;
  text-decoration: none;

  &:not([disabled]):hover {
    box-shadow: 0 4px 6px -1px ${(p) => p.$shadowColor1},
      0 2px 4px -1px ${(p) => p.$shadowColor2};
  }
  &[disabled] {
    box-shadow: none;

    opacity: 0.5;
    cursor: default;
  }
`;

const StyledLink = styled(Link)`
  ${ButtonStyle}
`;

const StyledAnchor = styled.a`
  ${ButtonStyle}
`;

const StyledButton = styled.button`
  ${ButtonStyle}
`;

const LoadingOverlay = styled.div<{ $buttonColor: string }>`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(p) => p.$buttonColor};
`;
