import { AxiosError } from 'axios';
import { useSnackBar } from 'contexts/SnackBarContext';
import { useCallback } from 'react';

export const useApiErrorSnackBar = () => {
  const [showSnackBar] = useSnackBar();

  const showApiErrorSnackBar = useCallback(
    (err: AxiosError) => {
      const getMessage = (err: AxiosError) => {
        if (err.response == null) {
          return 'エラーが発生しました';
        }
        switch (err.response.status) {
          case 400:
            return 'エラーが発生しました。入力内容が間違っていないかどうか確認してください。';
          case 401:
            return 'エラーが発生しました。ログインしているかどうか確認してください。';
          case 403:
            return 'エラーが発生しました。アクセス権限がありません。';
          case 404:
            return 'エラーが発生しました。指定されたリソースは見つかりませんでした。';
          case 500:
            return 'サーバーでエラーが発生しました。管理者へご連絡ください。';
          default:
            return `${err.response.status} エラーが発生しました`;
        }
      };

      showSnackBar('ERROR', getMessage(err));
    },
    [showSnackBar],
  );

  return [showApiErrorSnackBar] as const;
};
