import React from 'react';
import './LongOptionItem.scss';

import { generateButtonStyleVariables } from 'util/style';
import { Option } from 'util/question';

interface Props {
  option: Option;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
}
export const LongOptionItem: React.VFC<Props> = ({
  option,
  onClick,
  disabled,
}) => {
  const style = generateButtonStyleVariables(option.color);

  return (
    <button
      className="long-option-item"
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {option.text}
    </button>
  );
};
