import React from 'react';
import styled from 'styled-components';

import { ButtonStyleProps, generateButtonStyleProps } from 'util/style';
import { colors } from 'util/theme';

interface Props {
  errorTitle: string;
  errorSubTitle?: string;
  overview: string;
  detail: string;
}
export const ErrorPage: React.VFC<Props> = ({
  errorTitle,
  errorSubTitle,
  overview,
  detail,
}) => {
  const buttonStyleProps = generateButtonStyleProps(colors.primary[500]);
  return (
    <PageContainer>
      <Inner>
        <picture>
          <source type="image/webp" srcSet="/img/error_page_background.webp" />
          <source type="image/jpeg" srcSet="/img/error_page_background.jpg" />
          <Illustration
            src="/img/error_page_background.jpg"
            alt="木に風船が引っかかる絵"
          />
        </picture>

        <ErrorTitle>
          {errorTitle}
          <ErrorSubTitle>{errorSubTitle}</ErrorSubTitle>
        </ErrorTitle>
        <OverviewText>{overview}</OverviewText>
        <DetailText>{detail}</DetailText>
        <LinkButton href="/login" {...buttonStyleProps}>
          トップへ戻る
        </LinkButton>
      </Inner>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  height: 100vh;
  padding: 8rem 0 0 5rem;

  @media (max-width: ${(p) => p.theme.breaks.sm}) {
    padding: 4rem 0 0 2rem;
  }
`;

const Inner = styled.div`
  position: relative;
  height: 100%;
`;

const Illustration = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;

  max-width: 70%;
  max-height: 100%;

  @media (max-width: ${(p) => p.theme.breaks.sm}) {
    max-width: 90%;
  }
`;

const ErrorTitle = styled.h1`
  color: ${(p) => p.theme.colors.text.dark};
  font-size: 4.5rem;
  line-height: 1.2em;

  @media (max-width: ${(p) => p.theme.breaks.sm}) {
    font-size: 3.5rem;
  }
`;

const ErrorSubTitle = styled.span`
  display: block;
  font-size: 3rem;
  line-height: 1.2em;

  @media (max-width: ${(p) => p.theme.breaks.sm}) {
    width: 80%;
    font-size: 2rem;
  }
`;

const OverviewText = styled.p`
  width: 60%;
  margin-top: 2rem;

  color: ${(p) => p.theme.colors.text.base};
  font-size: 1.5rem;
  line-height: 1.5em;
  font-weight: 500;

  @media (max-width: ${(p) => p.theme.breaks.sm}) {
    margin-top: 1.5rem;
    width: 70%;
  }
`;

const DetailText = styled.p`
  width: 60%;
  margin-top: 0.5rem;

  color: ${(p) => p.theme.colors.text.light};
  font-size: 1rem;
  line-height: 1.5em;

  @media (max-width: ${(p) => p.theme.breaks.sm}) {
    width: 70%;
  }
`;

const LinkButton = styled.a<ButtonStyleProps>`
  display: block;

  max-width: 24rem;
  margin-top: 3.5rem;
  padding: 1em 1.5em;
  border-radius: 1.5em;
  background: ${(p) => p.$buttonColor};
  box-shadow: 0 2px 6px 0 ${(p) => p.$shadowColor1},
    0 2px 4px 0 ${(p) => p.$shadowColor2};

  transition: 0.3s box-shadow ease-in-out;
  overflow: hidden;

  color: #fff;
  font-size: 1.25rem;
  line-height: 1em;
  font-weight: bold;
  text-align: center;
  white-space: pre;
  text-overflow: ellipsis;

  @media (max-width: ${(p) => p.theme.breaks.sm}) {
    margin-top: 2rem;
    margin-right: 6rem;
    font-size: 1rem;
  }

  &:hover {
    box-shadow: 0 8px 12px -1px ${(p) => p.$shadowColor1},
      0 4px 8px -1px ${(p) => p.$shadowColor2};
  }
  &:focus {
    outline: none;
  }
`;
