import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { suggestionReducer } from './slices/suggestionSlice';
import { surveyReducer } from './slices/surveySlice';

export const store = configureStore({
  reducer: combineReducers({
    survey: surveyReducer,
    suggestion: suggestionReducer,
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
