import uniqueId from 'lodash.uniqueid';

export interface Option {
  key: string;
  text: string;
  color: string;
}

export const ALL_QUESTION_TYPES = [
  'BALLOON',
  'LONG',
  'NUMBER',
  'LINE',
  'MULTI_LINE',
] as const;
export type QuestionType = typeof ALL_QUESTION_TYPES[number];

export interface QuestionCondition {
  question: number;
  option: number;
}

export interface QuestionBase {
  key: string;
  name: string;
  text: string;
  condition: QuestionCondition[];
  required: boolean;
}

export interface SelectQuestion extends QuestionBase {
  type: 'BALLOON' | 'LONG';
  options: Option[];
}

export interface InputQuestion extends QuestionBase {
  type: 'NUMBER' | 'LINE' | 'MULTI_LINE';
}

export interface ResultBase {
  key: string;
  name: string;
  resultText: string;
  resultSubText: string;
  condition: QuestionCondition[];
}

export interface KirinResult extends ResultBase {
  type: 'KIRIN';
  imageUrl: string;
  title: string;
  subTitle: string;
  detail: string;
  itemName: string;
  itemPrice: string;
  buttonText: string;
  buttonUrl: string;
}

export type Result = KirinResult;

export const generateEmptyQuestion = (): SelectQuestion => ({
  key: uniqueId(),
  type: 'BALLOON',
  name: '',
  text: '',
  condition: [],
  required: true,
  options: [],
});

export const generateEmptyOption = (): Option => ({
  key: uniqueId(),
  text: '',
  color: '#4299E1',
});

export const generateEmptyResult = (): Result => ({
  key: uniqueId(),
  type: 'KIRIN',
  name: '',
  resultText: '',
  resultSubText: '',
  condition: [],
  imageUrl: '',
  title: '',
  subTitle: '',
  detail: '',
  itemName: '',
  itemPrice: '',
  buttonText: '',
  buttonUrl: '',
});

export const isQuestion = (
  item: SelectQuestion | InputQuestion | KirinResult,
): item is SelectQuestion | InputQuestion => {
  switch (item.type) {
    case 'BALLOON':
    case 'LONG':
    case 'NUMBER':
    case 'LINE':
    case 'MULTI_LINE':
      return true;
    default:
      return false;
  }
};

export const isSelectQuestion = (
  item: SelectQuestion | InputQuestion | KirinResult,
): item is SelectQuestion => {
  switch (item.type) {
    case 'BALLOON':
    case 'LONG':
      return true;
    default:
      return false;
  }
};

export const isInputQuestion = (
  item: SelectQuestion | InputQuestion | KirinResult,
): item is InputQuestion => {
  switch (item.type) {
    case 'NUMBER':
    case 'LINE':
    case 'MULTI_LINE':
      return true;
    default:
      return false;
  }
};

export const isResult = (
  item: SelectQuestion | InputQuestion | KirinResult,
): item is KirinResult => {
  switch (item.type) {
    case 'KIRIN':
      return true;
    default:
      return false;
  }
};

export const convertTagStringToArray = (tagString: string): string[] => {
  return [...new Set(tagString.split(',').filter((t) => t.length > 0))];
};
