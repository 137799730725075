import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import uniqueId from 'lodash.uniqueid';
import { SurveyQuestion } from 'util/api/survey';
import {
  InputQuestion,
  isInputQuestion,
  isSelectQuestion,
  Option,
  QuestionType,
  SelectQuestion,
} from 'util/question';

interface SurveyState {
  title: string;
  detail: string;
  tagString: string;
  questions: SurveyQuestion[];
}

const initialState: SurveyState = {
  title: '',
  detail: '',
  tagString: '',
  questions: [],
};

const isTypeSelect = (type: QuestionType): type is 'BALLOON' | 'LONG' => {
  return type === 'BALLOON' || type === 'LONG';
};

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    updateTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    updateDetail: (state, action: PayloadAction<string>) => {
      state.detail = action.payload;
    },
    updateTagString: (state, action: PayloadAction<string>) => {
      state.tagString = action.payload;
    },
    setQuestions: (state, action: PayloadAction<SurveyQuestion[]>) => {
      state.questions = action.payload;
    },
    addQuestion: (state, action: PayloadAction<SurveyQuestion>) => {
      state.questions.push(action.payload);
    },
    updateQuestion: (
      state,
      action: PayloadAction<{
        index: number;
        changes: Partial<
          Omit<SelectQuestion, 'type'> | Omit<InputQuestion, 'type'>
        >;
      }>,
    ) => {
      const { index, changes } = action.payload;
      const oldQuestion = state.questions[index];

      // Delete undefined values from changes
      (Object.keys(changes) as (keyof typeof changes)[]).forEach(
        (k) => changes[k] === undefined && delete changes[k],
      );

      if (isSelectQuestion(oldQuestion)) {
        state.questions[index] = {
          ...oldQuestion,
          ...changes,
        };
      } else {
        delete (changes as Partial<Omit<SelectQuestion, 'type'>>).options;
        state.questions[index] = {
          ...oldQuestion,
          ...changes,
        };
      }
    },
    updateQuestionType: (
      state,
      action: PayloadAction<{
        index: number;
        type: QuestionType;
      }>,
    ) => {
      const { index, type } = action.payload;
      const oldQuestion = state.questions[index];

      if (oldQuestion.type === type) {
        return;
      }

      // 同じ種類同士のtype切り替えであれば、そのまま更新
      if (isTypeSelect(oldQuestion.type) === isTypeSelect(type)) {
        // 型の整合性のための分岐
        if (isSelectQuestion(oldQuestion) && isTypeSelect(type)) {
          state.questions[index] = {
            ...oldQuestion,
            type,
          };
        } else if (isInputQuestion(oldQuestion) && !isTypeSelect(type)) {
          state.questions[index] = {
            ...oldQuestion,
            type,
          };
        }
        return;
      }

      if (oldQuestion.type === 'BALLOON' || oldQuestion.type === 'LONG') {
        // SelectQuestionからInputQuestionへ変換
        delete (oldQuestion as Partial<SelectQuestion>).options;
        state.questions[index] = {
          ...oldQuestion,
          type,
        };
      } else {
        // InputQuestionからSelectQuestionへ変換
        state.questions[index] = {
          ...oldQuestion,
          type,
          options: [],
        } as SelectQuestion;
      }
    },
    removeQuestion: (state, action: PayloadAction<number>) => {
      state.questions.splice(action.payload, 1);
    },
    moveQuestion: (
      state,
      action: PayloadAction<{ index: number; direction: 'UPPER' | 'LOWER' }>,
    ) => {
      const { index, direction } = action.payload;

      // 移動できない場合はそのまま
      if (index === 0 && direction === 'UPPER') return state;
      if (index === state.questions.length - 1 && direction === 'LOWER')
        return state;

      const questions = state.questions;
      const targetInd = index + (direction === 'UPPER' ? -1 : 1);

      /* 条件をインデックスで扱っているため、移動すると条件が指す質問が変わってしまう */
      // 入れ替える先の質問に、選択されている質問の条件がついている場合、その条件を削除
      if (questions[targetInd].condition?.[0].question === index) {
        questions[targetInd].condition = [];
      }

      // 選択されている質問に、入れ替える先の質問の条件がついている場合、その条件を削除
      if (questions[index].condition?.[0].question === targetInd) {
        questions[index].condition = [];
      }

      // 入れ替える先以降の質問に、選択されている質問の条件がついている場合、その条件を更新
      for (let i = targetInd; i < questions.length; i++) {
        const question = questions[i];

        if (question.condition == undefined) continue;

        if (question.condition?.[0].question === index) {
          questions[i].condition = [
            {
              question: targetInd,
              option: questions[i].condition[0].option,
            },
          ];
        } else if (question.condition?.[0].question === targetInd) {
          questions[i].condition = [
            {
              question: index,
              option: questions[i].condition[0].option,
            },
          ];
        }
      }

      [questions[targetInd], questions[index]] = [
        questions[index],
        questions[targetInd],
      ];
    },
    duplicateQuestion: (state, action: PayloadAction<number>) => {
      state.questions.splice(action.payload, 0, {
        ...state.questions[action.payload],
        key: uniqueId(),
      });
    },
    addOption: (
      state,
      action: PayloadAction<{ questionIndex: number; option: Option }>,
    ) => {
      const { questionIndex, option } = action.payload;
      const targetQuestion = state.questions[questionIndex];

      if (!isSelectQuestion(targetQuestion)) {
        throw new Error('Only select questions can have options');
      }

      targetQuestion.options.push(option);
    },
    updateOption: (
      state,
      action: PayloadAction<{
        questionIndex: number;
        index: number;
        changes: Partial<Option>;
      }>,
    ) => {
      const { questionIndex, index, changes } = action.payload;
      const targetQuestion = state.questions[questionIndex];

      if (!isSelectQuestion(targetQuestion)) {
        throw new Error('Only select questions can have options');
      }

      targetQuestion.options[index] = {
        ...targetQuestion.options[index],
        ...changes,
      };
    },
    removeOption: (
      state,
      action: PayloadAction<{ questionIndex: number; index: number }>,
    ) => {
      const { questionIndex, index } = action.payload;
      const targetQuestion = state.questions[questionIndex];

      if (!isSelectQuestion(targetQuestion)) {
        throw new Error('Only select questions can have options');
      }

      targetQuestion.options.splice(index, 1);
    },
    setCondition: (
      state,
      action: PayloadAction<{
        index: number;
        condition: SurveyQuestion['condition'];
      }>,
    ) => {
      const { index, condition } = action.payload;
      state.questions[index] = {
        ...state.questions[index],
        condition,
      };
    },
  },
});

export const surveyActions = surveySlice.actions;
export const surveyReducer = surveySlice.reducer;
