import { useCallback } from 'react';
import useSWR from 'swr';
import { AxiosError, AxiosResponse } from 'axios';
import uniqueId from 'lodash.uniqueid';
import {
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
} from 'util/apiAxios';
import {
  InputQuestion,
  Option,
  QuestionBase,
  SelectQuestion,
} from 'util/question';

// General Types
export type SurveyQuestion = SelectQuestion | InputQuestion;

export interface Survey {
  id: string;
  title: string;
  detail: string;
  tags: string[];
  questions: SurveyQuestion[];
  publishedId: string | null;
  answerNum: number;
  unreadAnswerNum: number;
  createdAt: Date;
  updatedAt: Date;
  exportedAt?: Date;
}

export interface SurveyAnswer {
  values: (string | number | null)[];
  createdAt: Date;
}

// Response Types
type OptionResponse = Omit<Option, 'key'>;

interface SelectQuestionResponse extends Omit<QuestionBase, 'key'> {
  type: 'BALLOON' | 'LONG';
  options: OptionResponse[];
}

interface InputQuestionResponse extends Omit<QuestionBase, 'key'> {
  type: 'NUMBER' | 'LINE' | 'MULTI_LINE';
}

type SurveyQuestionResponse = SelectQuestionResponse | InputQuestionResponse;

export type SurveyResponse = Modify<
  Survey,
  {
    questions: SurveyQuestionResponse[];
    createdAt: string;
    updatedAt: string;
    exportedAt: string | null;
  }
>;

type SurveyAnswerResponse = Modify<SurveyAnswer, { createdAt: string }>;

type SurveyRequest = Pick<Survey, 'title' | 'detail' | 'tags' | 'questions'>;

const convertResponseOption = (data: OptionResponse): Option => ({
  key: uniqueId(),
  ...data,
});

const convertResponseQuestion = (
  data: SurveyQuestionResponse,
): SurveyQuestion => {
  const base: QuestionBase = {
    key: uniqueId(),
    name: data.name,
    text: data.text,
    condition: data.condition,
    required: data.required,
  };

  if (data.type === 'BALLOON' || data.type === 'LONG') {
    return {
      type: data.type,
      options: data.options.map(convertResponseOption),
      ...base,
    };
  } else {
    return {
      type: data.type,
      ...base,
    };
  }
};

export const convertResponseSurvey = (data: SurveyResponse): Survey => ({
  ...data,
  questions: data.questions.map(convertResponseQuestion),
  createdAt: new Date(data.createdAt),
  updatedAt: new Date(data.updatedAt),
  exportedAt: data.exportedAt ? new Date(data.exportedAt) : undefined,
});

const convertResponseAnswer = (data: SurveyAnswerResponse): SurveyAnswer => ({
  ...data,
  createdAt: new Date(data.createdAt),
});

export const getSurveys = async (workspaceId: string): Promise<Survey[]> => {
  const { data } = await requestGet<SurveyResponse[]>(
    `/api/workspaces/${workspaceId}/surveys`,
  );
  return data.map(convertResponseSurvey);
};

export const getSurvey = async (
  workspaceId: string,
  surveyId: string,
): Promise<Survey> => {
  const { data } = await requestGet<SurveyResponse>(
    `/api/workspaces/${workspaceId}/surveys/${surveyId}`,
  );
  return convertResponseSurvey(data);
};

export const createSurvey = async (
  workspaceId: string,
  survey: SurveyRequest,
): Promise<Survey> => {
  const { data } = await requestPost<SurveyResponse, SurveyRequest>(
    `/api/workspaces/${workspaceId}/surveys`,
    survey,
  );
  return convertResponseSurvey(data);
};

export const updateSurvey = async (
  workspaceId: string,
  surveyId: string,
  survey: SurveyRequest,
): Promise<Survey> => {
  const { data } = await requestPut<SurveyResponse, SurveyRequest>(
    `/api/workspaces/${workspaceId}/surveys/${surveyId}`,
    survey,
  );
  return convertResponseSurvey(data);
};

export const deleteSurvey = (
  workspaceId: string,
  surveyId: string,
): Promise<AxiosResponse<unknown>> => {
  return requestDelete<unknown>(
    `/api/workspaces/${workspaceId}/surveys/${surveyId}`,
  );
};

export const getSurveyAnswers = async (
  workspaceId: string,
  surveyId: string,
): Promise<SurveyAnswer[]> => {
  const { data } = await requestGet<SurveyAnswerResponse[]>(
    `/api/workspaces/${workspaceId}/surveys/${surveyId}/answers`,
  );
  return data.map(convertResponseAnswer);
};

export const publishSurvey = async (
  workspaceId: string,
  surveyId: string,
): Promise<Survey> => {
  const { data } = await requestPost<SurveyResponse, never>(
    `/api/workspaces/${workspaceId}/surveys/${surveyId}/publish`,
  );
  return convertResponseSurvey(data);
};

export const unpublishSurvey = async (
  workspaceId: string,
  surveyId: string,
): Promise<Survey> => {
  const { data } = await requestPost<SurveyResponse, never>(
    `/api/workspaces/${workspaceId}/surveys/${surveyId}/unpublish`,
  );
  return convertResponseSurvey(data);
};

export const useSurveys = (
  workspaceId: string,
): {
  surveys?: Survey[];
  error?: AxiosError<unknown>;
  isLoading: boolean;
  refetchSurveys: () => void;
} => {
  const { data, error, mutate } = useSWR<Survey[], AxiosError<unknown>>(
    `/api/workspaces/${workspaceId}/surveys`,
    () => getSurveys(workspaceId),
  );
  const refetchSurveys = useCallback(() => {
    mutate();
  }, [mutate]);
  return { surveys: data, error, isLoading: !data && !error, refetchSurveys };
};
