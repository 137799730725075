import React, { useState, useEffect, useRef } from 'react';
import './MultiLineQuestion.scss';

import { CircleAnimator } from './CircleAnimator';
import { RaisedButton } from 'components/common/form/RaisedButton';

interface Props {
  onSubmit: (value: string) => void;
  isRequired: boolean;
}
export const MultiLineQuestion: React.VFC<Props> = ({
  onSubmit,
  isRequired,
}) => {
  const [input, setInput] = useState('');
  const [isValid, setIsValid] = useState(!isRequired);
  const [answered, setAnswered] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const timeout = useRef<number>();

  // PurpleCircleは紫色の先に拡大する円
  const [isPurpleCircleAnimating, setIsPurpleCircleAnimating] = useState(false);
  const [purpleCircleStyle, setPurpleCircleStyle] =
    useState<React.CSSProperties>();
  // WhiteCircleは後から拡大する円
  const [isWhiteCircleAnimating, setIsWhiteCircleAnimating] = useState(false);
  const [whiteCircleStyle, setWhiteCircleStyle] =
    useState<React.CSSProperties>();

  // アニメーション完了後にフォーカス
  // autoFocusを使うと、アニメーションがバグる
  useEffect(() => {
    timeout.current = window.setTimeout(() => {
      textareaRef.current?.focus();
    }, 3500);

    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  const handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
    e,
  ) => {
    setInput(e.target.value);

    if (e.target.value.length > 0) {
      setIsValid(true);
    } else if (isRequired) {
      setIsValid(false);
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!isValid || answered) return;
    setAnswered(true);

    // 円の位置を調整
    // 円は画面中央を中心に拡大していく
    const circleStyle: React.CSSProperties = {};
    circleStyle.left = window.innerWidth / 2 - 50; // 円のサイズは100pxなので、左上座標を50px左上にずらして中央揃えにする
    circleStyle.top = window.innerHeight / 2 - 50;
    setPurpleCircleStyle(circleStyle);
    setWhiteCircleStyle(circleStyle);

    setIsPurpleCircleAnimating(true);

    // 300ms後に白円もアニメーション開始
    setTimeout(() => {
      setIsWhiteCircleAnimating(true);
    }, 300);
  };

  return (
    <>
      <form className="multi-line-question" onSubmit={handleSubmit}>
        <textarea
          className="multi-line-question--textarea"
          value={input}
          onChange={handleInputChange}
          rows={4}
          ref={textareaRef}
        />

        <div className="multi-line-question--button-wrapper">
          <RaisedButton type="submit" color="--purple500" disabled={!isValid}>
            次の質問へ
          </RaisedButton>
        </div>
      </form>
      <CircleAnimator
        className="multi-line-question--purple-circle"
        color="--purple500"
        animate={isPurpleCircleAnimating}
        style={purpleCircleStyle}
      />
      <CircleAnimator
        className="multi-line-question--white-circle"
        color="#fff"
        onAnimated={() => onSubmit(input)}
        animate={isWhiteCircleAnimating}
        style={whiteCircleStyle}
      />
    </>
  );
};
