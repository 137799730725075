import React, { useState, useEffect, useRef } from 'react';

import { generateButtonStyleVariables } from 'util/style';
import styled, { keyframes } from 'styled-components';

interface Props {
  duration: number;
  color?: string;
  onHide?: () => void;
  children?: React.ReactNode;
}
export const SnackBar: React.VFC<Props> = ({
  duration,
  color,
  onHide,
  children,
}) => {
  const style = generateButtonStyleVariables(color || '#000');

  const [isShown, setIsShown] = useState(true);
  const timeout = useRef<number>();

  useEffect(() => {
    timeout.current = window.setTimeout(() => {
      setIsShown(false);

      // アニメーション完了までタイムアウト
      timeout.current = window.setTimeout(() => {
        onHide?.();
      }, 300);
    }, duration);

    // コンポーネント破棄時にタイムアウトを解除
    return () => clearTimeout(timeout.current);
  }, [duration, onHide]);

  return (
    <Container $isShown={isShown} style={style}>
      <Content>{children}</Content>
    </Container>
  );
};

const slideIn = keyframes`
  0% {
    transform: translateY(calc(-3rem - 100%));
  }
  100% {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    transform: translateY(calc(-3rem - 100%));
  }
`;

const Container = styled.div<{ $isShown: boolean }>`
  position: fixed;
  top: 2rem;
  left: 0;
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;

  pointer-events: none;
  animation: ${(p) => (p.$isShown ? slideIn : slideOut)} 0.3s
    ${(p) => p.theme.easings.easeOut} forwards;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: calc(100vw - 4rem);
  min-width: 16rem;
  padding: 1rem 2rem;

  border-radius: 0.25rem;
  background: var(--color);
  box-shadow: 0 1px 3px 0 var(--shadow-color-1),
    0 1px 2px 0 var(--shadow-color-2);

  color: #fff;
  font-weight: 500;

  svg {
    margin-right: 0.5rem;
    font-size: 1.5rem;
  }
`;
