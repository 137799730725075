import React from 'react';
import './WaitingPage.scss';

import { CircleButton } from './CircleButton';

interface Props {
  title: string;
  detail: string;
  onStart: () => void;
}
export const WaitingPage: React.VFC<Props> = ({ title, detail, onStart }) => {
  return (
    <div className="start">
      <h1 className="start--title">{title}</h1>
      <p className="start--detail">{detail}</p>
      <CircleButton
        color="#ffffff"
        className="start--button"
        onAnimated={onStart}
      >
        はじめる
      </CircleButton>

      <footer className="start--footer">
        <div className="start--footer__container">
          <img
            className="start--footer__logo"
            src="/img/logo.svg"
            alt="Balloon Logo"
          />
          <p className="start--footer__copyright">© 2020 prane.jpn</p>
        </div>
      </footer>
    </div>
  );
};
