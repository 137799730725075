import React from 'react';
import { ErrorPage } from 'components/common/ErrorPage';
import { BaseLayout } from 'layouts/BaseLayout';

export const NotFoundPage: React.VFC = () => {
  return (
    <BaseLayout title="お探しのページが見つかりませんでした">
      <ErrorPage
        errorTitle="404"
        errorSubTitle="PAGE NOT FOUND"
        overview="お探しのページが見つかりませんでした"
        detail="あなたがアクセスしたページは、削除、変更されたか、現在利用できない可能性があります。"
      />
    </BaseLayout>
  );
};
