import React from 'react';
import styled from 'styled-components';

import { colors } from 'util/theme';
import { LoadingSpinner } from 'components/common/icons/LoadingSpinner';

export const LazyloadFallback: React.VFC = () => (
  <Container>
    <LoadingSpinner size="2rem" color={colors.gray[300]} />
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
`;
