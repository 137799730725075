import { useCallback } from 'react';
import useSWR from 'swr';
import { AxiosError, AxiosResponse } from 'axios';
import uniqueId from 'lodash.uniqueid';
import {
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
} from 'util/apiAxios';
import {
  KirinResult,
  Option,
  QuestionBase,
  SelectQuestion,
} from 'util/question';

// General Types
export type SuggestionQuestion = SelectQuestion;
export type SuggestionResult = KirinResult;
export type SuggestionQuestionType = SuggestionQuestion['type'];
export type SuggestionResultType = SuggestionResult['type'];
export type SuggestionItemType = SuggestionQuestionType | SuggestionResultType;

export interface Suggestion {
  id: string;
  title: string;
  detail: string;
  tags: string[];
  questions: (SuggestionQuestion | SuggestionResult)[];
  publishedId: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface SuggestionAnswer {
  values: (string | number | null)[];
  createdAt: Date;
}

// Response Types
type OptionResponse = Omit<Option, 'key'>;

interface SelectQuestionResponse extends Omit<QuestionBase, 'key'> {
  type: 'BALLOON' | 'LONG';
  options: OptionResponse[];
}

type SuggestionResultResponse = Omit<SuggestionResult, 'key'>;

export type SuggestionResponse = Modify<
  Suggestion,
  {
    questions: (SelectQuestionResponse | SuggestionResultResponse)[];
    createdAt: string;
    updatedAt: string;
  }
>;

type SuggestionAnswerResponse = Modify<SuggestionAnswer, { createdAt: string }>;

type SuggestionRequest = Pick<
  Suggestion,
  'title' | 'detail' | 'tags' | 'questions'
>;

const convertResponseOption = (data: OptionResponse): Option => ({
  key: uniqueId(),
  ...data,
});

const convertResponseQuestion = (
  data: SelectQuestionResponse,
): SuggestionQuestion => {
  return {
    key: uniqueId(),
    type: data.type,
    name: data.name,
    text: data.text,
    options: data.options.map(convertResponseOption),
    condition: data.condition,
    required: data.required,
  };
};

const convertResponseResult = (
  data: SuggestionResultResponse,
): SuggestionResult => {
  return {
    key: uniqueId(),
    ...data,
  };
};

const convertResponseQuestionOrResult = (
  data: SelectQuestionResponse | SuggestionResultResponse,
): SuggestionQuestion | SuggestionResult => {
  if (data.type === 'KIRIN') {
    return convertResponseResult(data);
  } else {
    return convertResponseQuestion(data);
  }
};

export const convertResponseSuggestion = (
  data: SuggestionResponse,
): Suggestion => ({
  ...data,
  questions: data.questions.map(convertResponseQuestionOrResult),
  createdAt: new Date(data.createdAt),
  updatedAt: new Date(data.updatedAt),
});

const convertResponseAnswer = (
  data: SuggestionAnswerResponse,
): SuggestionAnswer => ({
  ...data,
  createdAt: new Date(data.createdAt),
});

export const getSuggestions = async (
  workspaceId: string,
): Promise<Suggestion[]> => {
  const { data } = await requestGet<SuggestionResponse[]>(
    `/api/workspaces/${workspaceId}/suggestions`,
  );
  return data.map(convertResponseSuggestion);
};

export const getSuggestion = async (
  workspaceId: string,
  suggestionId: string,
): Promise<Suggestion> => {
  const { data } = await requestGet<SuggestionResponse>(
    `/api/workspaces/${workspaceId}/suggestions/${suggestionId}`,
  );
  return convertResponseSuggestion(data);
};

export const createSuggestion = async (
  workspaceId: string,
  suggestion: SuggestionRequest,
): Promise<Suggestion> => {
  const { data } = await requestPost<SuggestionResponse, SuggestionRequest>(
    `/api/workspaces/${workspaceId}/suggestions`,
    suggestion,
  );
  return convertResponseSuggestion(data);
};

export const updateSuggestion = async (
  workspaceId: string,
  suggestionId: string,
  suggestion: SuggestionRequest,
): Promise<Suggestion> => {
  const { data } = await requestPut<SuggestionResponse, SuggestionRequest>(
    `/api/workspaces/${workspaceId}/suggestions/${suggestionId}`,
    suggestion,
  );
  return convertResponseSuggestion(data);
};

export const deleteSuggestion = (
  workspaceId: string,
  suggestionId: string,
): Promise<AxiosResponse<unknown>> => {
  return requestDelete<unknown>(
    `/api/workspaces/${workspaceId}/suggestions/${suggestionId}`,
  );
};

export const getSuggestionAnswers = async (
  workspaceId: string,
  suggestionId: string,
): Promise<SuggestionAnswer[]> => {
  const { data } = await requestGet<SuggestionAnswerResponse[]>(
    `/api/workspaces/${workspaceId}/suggestions/${suggestionId}/answers`,
  );
  return data.map(convertResponseAnswer);
};

export const publishSuggestion = async (
  workspaceId: string,
  suggestionId: string,
): Promise<Suggestion> => {
  const { data } = await requestPost<SuggestionResponse, never>(
    `/api/workspaces/${workspaceId}/suggestions/${suggestionId}/publish`,
  );
  return convertResponseSuggestion(data);
};

export const unpublishSuggestion = async (
  workspaceId: string,
  suggestionId: string,
): Promise<Suggestion> => {
  const { data } = await requestPost<SuggestionResponse, never>(
    `/api/workspaces/${workspaceId}/suggestions/${suggestionId}/unpublish`,
  );
  return convertResponseSuggestion(data);
};

export const useSuggestions = (
  workspaceId: string,
): {
  suggestions?: Suggestion[];
  error?: AxiosError<unknown>;
  isLoading: boolean;
  refetchSuggestions: () => void;
} => {
  const { data, error, mutate } = useSWR<Suggestion[], AxiosError<unknown>>(
    `/api/workspaces/${workspaceId}/suggestions`,
    () => getSuggestions(workspaceId),
  );
  const refetchSuggestions = useCallback(() => {
    mutate();
  }, [mutate]);
  return {
    suggestions: data,
    error,
    isLoading: !data && !error,
    refetchSuggestions,
  };
};
