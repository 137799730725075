import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

interface Props {
  onClick: () => void;
}
export const BackToTopButton: React.VFC<Props> = ({ onClick }) => {
  return (
    <Position>
      <StyledButton type="button" onClick={onClick}>
        <StyledFontAwesomeIcon icon={faArrowLeft} />
        待機画面へもどる
      </StyledButton>
    </Position>
  );
};

const Position = styled.div`
  position: absolute;
  bottom: 3vh;
  left: 3vh;

  animation: 1s ${(p) => p.theme.easings.easeOut} 1.5s backwards
    survey-back-btn-animation;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;

  transition: 0.2s;
  user-select: none;

  color: ${(p) => p.theme.colors.primary[500]};
  font-size: 2vh;
  line-height: 1em;
  font-weight: bold;

  &:not(:disabled):hover {
    opacity: 0.8;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 2vh;
  margin-right: 0.75em;
`;
