import React from 'react';
import { Link } from 'react-router-dom';
import './IconButton.scss';

interface Props {
  to?: string;
  onClick?: () => void;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}
export const IconButton: React.VFC<Props> = ({
  to,
  onClick,
  title,
  className,
  style,
  children,
}) => {
  if (to) {
    return (
      <Link
        className={`icon-button ${className}`}
        title={title}
        style={style}
        onClick={onClick}
        to={to}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      className={`icon-button ${className}`}
      type="button"
      title={title}
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
