import React from 'react';

import { InputQuestion, SelectQuestion } from 'util/question';
import { BalloonOptions } from './BalloonOptions';
import { LongOptions } from './LongOptions';
import { LineQuestion } from './LineQuestion';
import { MultiLineQuestion } from './MultiLineQuestion';
import { NumberQuestion } from './NumberQuestion';

interface Props {
  question: SelectQuestion | InputQuestion;
  onAnswer: (value: number | string) => void;
}
export const AnswerQuestion: React.VFC<Props> = ({ question, onAnswer }) => {
  return (
    <div className="answer--grid-contianer">
      <div className="answer--white-cover" />

      <section className="answer--question">
        <div className="answer--question__spacer1" />
        <h1 className="answer--question__text">{question.text}</h1>
        <div className="answer--question__spacer2" />
      </section>

      <section className="answer--options">
        {question.type === 'BALLOON' ? (
          <BalloonOptions options={question.options} onSubmit={onAnswer} />
        ) : question.type === 'LONG' ? (
          <LongOptions options={question.options} onSubmit={onAnswer} />
        ) : question.type === 'LINE' ? (
          <LineQuestion onSubmit={onAnswer} isRequired={question.required} />
        ) : question.type === 'MULTI_LINE' ? (
          <MultiLineQuestion
            onSubmit={onAnswer}
            isRequired={question.required}
          />
        ) : (
          <NumberQuestion onSubmit={onAnswer} />
        )}
      </section>
    </div>
  );
};
