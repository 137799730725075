import React from 'react';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router';
import { parse } from 'query-string';

import { usePublished } from 'util/api/published';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { BaseLayout } from 'layouts/BaseLayout';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { DemoBadge } from 'components/answer/DemoBadge';
import { NotFoundPage } from 'components/answer/NotFoundPage';
import { SuggestionAnswer } from './SuggestionAnswer';
import { SurveyAnswer } from './SurveyAnswer';

interface Params {
  publishedId: string;
}

export const UniversalAnswerPage: React.VFC = () => {
  const { publishedId } = useParams<Params>();
  const { published, isLoading, error } = usePublished(publishedId);
  const windowHeight = useWindowHeight();
  const location = useLocation();
  const isDemo = !!parse(location.search).demo;

  if (error?.code === '404') {
    return <NotFoundPage />;
  }
  // TODO: Show error page

  return (
    <BaseLayout title={published?.title} shouldSetRawTitle>
      <Container $height={`${windowHeight}px`}>
        {published == null || isLoading ? (
          <LoadingIndicator size="10vh" color="white" />
        ) : published.type === 'SURVEY' ? (
          <SurveyAnswer
            publishedId={publishedId}
            survey={published}
            isDemo={isDemo}
          />
        ) : (
          <SuggestionAnswer
            publishedId={publishedId}
            suggestion={published}
            isDemo={isDemo}
          />
        )}
        {isDemo && <DemoBadge />}
      </Container>
    </BaseLayout>
  );
};

const Container = styled.div<{ $height: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: ${(p) => p.$height};
  background: ${(p) =>
    `linear-gradient(-52deg, ${p.theme.colors.primaryGradient.from}, ${p.theme.colors.primaryGradient.to})`};

  overflow: hidden;
`;
