import {
  browserLocalPersistence,
  inMemoryPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from '@firebase/auth';
import { FirebaseError } from '@firebase/util';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { getUser, User } from 'util/api/auth';
import { auth } from 'util/firebase';
import { useThrowError } from 'hooks/useThrowError';
import { useSnackBar } from './SnackBarContext';

interface UserContextValue {
  user: User | undefined;
  isInitialized: boolean;
}
interface UserDispatchContextValue {
  login: (
    email: string,
    password: string,
    doRemember: boolean,
  ) => Promise<FirebaseError | void>;
  logout: () => Promise<void>;
  updateUserInContext: (user?: User) => Promise<void>;
}

export const UserContext = createContext<UserContextValue>({
  user: undefined,
  isInitialized: false,
});
export const UserDispatchContext = createContext<UserDispatchContextValue>({
  login: () => Promise.resolve(void 0),
  logout: () => Promise.resolve(void 0),
  updateUserInContext: () => Promise.resolve(void 0),
});

interface Props {
  children: React.ReactNode;
}
export const UserContextProvider: React.VFC<Props> = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [isInitialized, setIsInitialized] = useState(false);
  const [showSnackBar] = useSnackBar();
  const throwError = useThrowError();

  const login = useCallback(
    async (
      email: string,
      password: string,
      doRemember: boolean,
      // Errorの型は正しくはAuthErrorだがType Guardがないため、base classであるFirbaseErrorを返り値とする
    ): Promise<FirebaseError | void> => {
      await setPersistence(
        auth,
        doRemember ? browserLocalPersistence : inMemoryPersistence,
      );

      try {
        await signInWithEmailAndPassword(auth, email, password);
      } catch (err) {
        if (err instanceof FirebaseError) {
          return err;
        } else {
          throw err;
        }
      }
    },
    [],
  );

  const logout = useCallback(async () => {
    await signOut(auth);
    setUser(undefined);
  }, []);

  // Firebase Authenticationのログインを確認
  useEffect(() => {
    return onAuthStateChanged(auth, async (user) => {
      if (user != null) {
        try {
          setUser(await getUser(user.uid));
        } catch (err) {
          showSnackBar('ERROR', 'ログインに失敗しました');
          logout();
          throwError(err);
        }
      }
      setIsInitialized(true);
    });
  }, [logout, showSnackBar, throwError]);

  const updateUserInContext = useCallback(
    async (updatedUser?: User) => {
      if (user == null) {
        throwError('Login before update user');
        return;
      }

      // 更新後のデータがある場合、それをすぐに反映したあとで正確なデータに更新
      if (updatedUser != null) {
        setUser(updatedUser);
      }
      setUser(await getUser(user.id));
    },
    [throwError, user],
  );

  return (
    <UserContext.Provider
      value={useMemo(
        () => ({
          user,
          isInitialized,
        }),
        [user, isInitialized],
      )}
    >
      <UserDispatchContext.Provider
        value={useMemo(
          () => ({
            login,
            logout,
            updateUserInContext,
          }),
          [login, logout, updateUserInContext],
        )}
      >
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextValue => {
  return useContext(UserContext);
};

export const useUserDispatch = (): UserDispatchContextValue => {
  return useContext(UserDispatchContext);
};
