import React from 'react';
import { ErrorBoundary } from '@sentry/react';
import { ErrorPage } from './ErrorPage';

interface Props {
  children: React.ReactNode;
}
export const RootErrorBoundary: React.VFC<Props> = ({ children }) => {
  return (
    <ErrorBoundary
      fallback={
        <ErrorPage
          errorTitle="ERROR"
          overview="プログラムの実行中に問題が発生しました"
          detail="ご迷惑をおかけして申し訳ございません。リクエストの処理中に問題が発生しました。お手数ですが再度お試しください。"
        />
      }
    >
      {children}
    </ErrorBoundary>
  );
};
