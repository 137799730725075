import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import './CircleAnimator.scss';

import { getColor } from 'util/style';

const ANIMATION_DURATION = 600;

interface Props {
  color: string;
  animate: boolean;
  onAnimated?: () => void;
  className?: string;
  style?: React.CSSProperties;
}
export const CircleAnimator: React.VFC<Props> = ({
  color,
  onAnimated,
  animate,
  className,
  style,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [circleStyle, setCircleStyle] = useState<React.CSSProperties>();
  const circleRef = useRef<HTMLDivElement>(null);

  const colorStyle = {
    background: getColor(color).rgb().string(),
  };

  useEffect(() => {
    if (!animate) {
      setCircleStyle({});
      return;
    }

    if (isAnimating) {
      return;
    }

    const circle = circleRef.current;
    if (circle != null) {
      // 拡大率を計算しtransformする
      const bodyRect = document.body.getBoundingClientRect();
      const circleRect = circle.getBoundingClientRect();
      const circleCenterX = circleRect.x + circleRect.width / 2;
      const circleCenterY = circleRect.y + circleRect.height / 2;
      const maxX = Math.max(
        circleCenterX,
        Math.abs(bodyRect.width - circleCenterX),
      );
      const maxY = Math.max(
        circleCenterY,
        Math.abs(bodyRect.height - circleCenterY),
      );
      const maxRadius = Math.sqrt(maxX ** 2 + maxY ** 2);
      const ratio =
        maxRadius / Math.max(circle.clientWidth / 2, circle.clientHeight / 2);

      setIsAnimating(true);
      setCircleStyle({
        transform: `scale(${ratio})`,
      });
    }

    setTimeout(() => {
      onAnimated?.();
    }, ANIMATION_DURATION);
  }, [animate, onAnimated, isAnimating]);

  return (
    <div
      className={classNames('circle-animator', className, {
        animating: isAnimating,
      })}
      style={{ ...colorStyle, ...style, ...circleStyle }}
      ref={circleRef}
    />
  );
};
