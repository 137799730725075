import React, { useState, useRef } from 'react';
import './LongOptions.scss';

import { Option } from 'util/question';
import { CircleAnimator } from './CircleAnimator';
import { LongOptionItem } from './LongOptionItem';

interface Props {
  options: Option[];
  onSubmit: (index: number) => void;
}
export const LongOptions: React.VFC<Props> = ({ options, onSubmit }) => {
  // ひとつの選択肢がクリックされたら他の選択肢はクリックできないようにする
  const [isSelected, setIsSelected] = useState(false);

  // OptionCircleは、クリックされた選択肢の色で塗りつぶされた、先に拡大する円
  const [isOptionCircleAnimating, setIsOptionCircleAnimating] = useState(false);
  const [optionCircleStyle, setOptionCircleStyle] =
    useState<React.CSSProperties>();
  // WhiteCircleは後から拡大する円
  const [isWhiteCircleAnimating, setIsWhiteCircleAnimating] = useState(false);
  const [whiteCircleStyle, setWhiteCircleStyle] =
    useState<React.CSSProperties>();

  const selectedOption = useRef<number>(0);

  const onOptionClicked = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    idx: number,
  ) => {
    if (isSelected) return;
    setIsSelected(true);

    selectedOption.current = idx;

    // 円の位置を調整
    const circleStyle: React.CSSProperties = {};
    circleStyle.left = e.clientX - 50; // 円のサイズは100pxなので、左上座標を50px左上にずらして中央揃えにする
    circleStyle.top = e.clientY - 50;
    setOptionCircleStyle({ background: options[idx].color, ...circleStyle });
    setWhiteCircleStyle(circleStyle);

    setIsOptionCircleAnimating(true);

    // 300ms後に白円もアニメーション開始
    setTimeout(() => {
      setIsWhiteCircleAnimating(true);
    }, 300);
  };

  return (
    <>
      <div className="long-options">
        {options.map((option, idx) => (
          <LongOptionItem
            option={option}
            onClick={(e) => onOptionClicked(e, idx)}
            disabled={isSelected}
            key={idx}
          />
        ))}
      </div>
      <CircleAnimator
        className="long-options--option-circle"
        color="#fff"
        animate={isOptionCircleAnimating}
        style={optionCircleStyle}
      />
      <CircleAnimator
        className="long-options--white-circle"
        color="#fff"
        onAnimated={() => onSubmit(selectedOption.current)}
        animate={isWhiteCircleAnimating}
        style={whiteCircleStyle}
      />
    </>
  );
};
