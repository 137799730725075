import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { auth } from './firebase';
import { reportError } from 'util/error';

const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://asia-northeast1-prane-balloon.cloudfunctions.net'
    : 'http://localhost:5001/prane-balloon/asia-northeast1';

interface ErrorResponse {
  error: string;
  message: string;
  statusCode: number;
}

const setOptions = async (
  options: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  // Local/SesssionStorageに保存されているアクセストークンを、Authorizationヘッダに付加する
  options = { ...options };
  const token = await auth.currentUser?.getIdToken();

  if (options.headers == null) options.headers = {};
  options.headers.Authorization = `Bearer ${token}`;

  // baseURLを付加する
  if (options.baseURL == null) {
    options.baseURL = BASE_URL;
  }

  return options;
};

// リクエストがリジェクトされたときにまとめてハンドリングする
// 予期せぬエラーだった場合はSentryにレポートする
const handleReject = (err: AxiosError<ErrorResponse>) => {
  // サーバーエラー系が発生した場合
  if (err.response?.status && err.response.status >= 500) {
    reportError(err);
  }
  // 認証系エラーが発生した場合（Firebase Authenticationで保護されているはずなので、認証エラーは出ないはず）
  if (err.response?.status && err.response.status === 401) {
    reportError(err);
  }
  return Promise.reject(err);
};

export const requestGet = async <T>(
  url: string,
  options: AxiosRequestConfig = {},
): Promise<AxiosResponse<T>> => {
  options = await setOptions(options);

  return axios.get<T>(url, options).catch(handleReject);
};

export const requestPost = async <T, U>(
  url: string,
  payload?: U,
  options: AxiosRequestConfig = {},
): Promise<AxiosResponse<T>> => {
  options = await setOptions(options);

  return axios.post<T>(url, payload, options).catch(handleReject);
};

export const requestPut = async <T, U>(
  url: string,
  payload?: U,
  options: AxiosRequestConfig = {},
): Promise<AxiosResponse<T>> => {
  options = await setOptions(options);

  return axios.put<T>(url, payload, options).catch(handleReject);
};

export const requestPatch = async <T, U>(
  url: string,
  payload?: U,
  options: AxiosRequestConfig = {},
): Promise<AxiosResponse<T>> => {
  options = await setOptions(options);

  return axios.patch<T>(url, payload, options).catch(handleReject);
};

export const requestDelete = async <T>(
  url: string,
  options: AxiosRequestConfig = {},
): Promise<AxiosResponse<T>> => {
  options = await setOptions(options);

  return axios.delete<T>(url, options).catch(handleReject);
};
