import Color from 'color';
import React from 'react';

export const getColor = (colorCode: string): Color => {
  try {
    // CSS Variableが指定されている場合、カラーコードを取ってくる
    if (colorCode.startsWith('--')) {
      return Color(
        getComputedStyle(document.body).getPropertyValue(colorCode).trim(),
      );
    }

    return Color(colorCode);
  } catch (e) {
    console.error(e);
    return Color('#000000');
  }
};

export const generateButtonStyleVariables = (
  colorCode: string,
): React.CSSProperties => {
  const color = getColor(colorCode);

  return {
    ['--color' as string]: color.rgb().string(),
    ['--shadow-color-1' as string]: Color({ alpha: 0.4, ...color.object() })
      .rgb()
      .string(),
    ['--shadow-color-2' as string]: Color({ alpha: 0.24, ...color.object() })
      .rgb()
      .string(),
    ['--hover-background-color' as string]: Color({
      alpha: 0.15,
      ...color.object(),
    })
      .rgb()
      .string(),
  };
};

export interface ButtonStyleProps {
  $buttonColor: string;
  $shadowColor1: string;
  $shadowColor2: string;
  $hoverBackgroundColor: string;
}

export const generateButtonStyleProps = (
  colorCode: string,
): ButtonStyleProps => {
  const color = getColor(colorCode);

  return {
    $buttonColor: color.rgb().string(),
    $shadowColor1: Color({ alpha: 0.4, ...color.object() })
      .rgb()
      .string(),
    $shadowColor2: Color({ alpha: 0.24, ...color.object() })
      .rgb()
      .string(),
    $hoverBackgroundColor: Color({
      alpha: 0.15,
      ...color.object(),
    })
      .rgb()
      .string(),
  };
};
