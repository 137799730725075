import React, { useState } from 'react';

import { logCustomEvent } from 'util/analytics';
import { Suggestion } from 'util/api/suggestion';
import { postAnswerForSuggestion } from 'util/api/published';

import { WaitingPage } from 'components/answer/WaitingPage';
import { AnswerPage, AnswerValues } from 'components/answer/AnswerPage';

type PageState = 'WAITING' | 'ANSWERING';

interface Props {
  publishedId: string;
  suggestion: Suggestion;
  isDemo: boolean;
}

export const SuggestionAnswer: React.VFC<Props> = ({
  publishedId,
  suggestion,
  isDemo,
}) => {
  const [pageState, setPageState] = useState<PageState>('WAITING');

  const sendAnswer = async (answer: AnswerValues) => {
    if (isDemo) {
      return;
    }

    logCustomEvent('answer_published_result', {
      type: 'SUGGESTION',
      publishedId,
    });

    try {
      await postAnswerForSuggestion(publishedId, answer);
    } catch (err) {
      // 診断では回答の送信に失敗しても回答者にとって問題がないため、エラーは表示しない
    }
  };

  const moveToAnswerPage = () => {
    setPageState('ANSWERING');
    logCustomEvent('answer_published_start', {
      type: 'SUGGESTION',
      publishedId,
    });
  };

  const backToWaitingPage = () => {
    setPageState('WAITING');
  };

  const logExitEvent = (progress: number) => {
    // Do not send an event if the user completed the suggestion
    if (progress >= 1) {
      return;
    }

    logCustomEvent('answer_published_exit', {
      type: 'SUGGESTION',
      publishedId,
      progress,
    });
  };

  if (pageState === 'WAITING') {
    return (
      <WaitingPage
        title={suggestion.title}
        detail={suggestion.detail}
        onStart={moveToAnswerPage}
      />
    );
  } else {
    return (
      <AnswerPage
        questions={suggestion.questions}
        onSendAnswer={sendAnswer}
        onComplete={backToWaitingPage}
        onExit={logExitEvent}
      />
    );
  }
};
