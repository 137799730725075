import React, { useState } from 'react';
import './NumberQuestion.scss';

import { CircleAnimator } from './CircleAnimator';
import { RaisedButton } from 'components/common/form/RaisedButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackspace } from '@fortawesome/pro-solid-svg-icons';

type ButtonType = 'back' | 'clear' | '00' | number;

interface Props {
  onSubmit: (value: number) => void;
}
export const NumberQuestion: React.VFC<Props> = ({ onSubmit }) => {
  const [input, setInput] = useState(0);
  const [answered, setAnswered] = useState(false);

  // PurpleCircleは紫色の先に拡大する円
  const [isPurpleCircleAnimating, setIsPurpleCircleAnimating] = useState(false);
  const [purpleCircleStyle, setPurpleCircleStyle] =
    useState<React.CSSProperties>();
  // WhiteCircleは後から拡大する円
  const [isWhiteCircleAnimating, setIsWhiteCircleAnimating] = useState(false);
  const [whiteCircleStyle, setWhiteCircleStyle] =
    useState<React.CSSProperties>();

  const handleBtnClick = (btn: ButtonType) => {
    if (btn === 'back') {
      // backspace
      setInput(Math.floor(input / 10));
    } else if (btn === 'clear') {
      // clear
      setInput(0);
    } else if (btn === '00') {
      // 00
      setInput(input * 100);
    } else if (!Number.isNaN(Number(btn))) {
      // number
      setInput(input * 10 + Number(btn));
    }
  };

  const handleSubmit = () => {
    if (answered) return;
    setAnswered(true);

    // 円の位置を調整
    // 円は画面中央を中心に拡大していく
    const circleStyle: React.CSSProperties = {};
    circleStyle.left = window.innerWidth / 2 - 50; // 円のサイズは100pxなので、左上座標を50px左上にずらして中央揃えにする
    circleStyle.top = window.innerHeight / 2 - 50;
    setPurpleCircleStyle(circleStyle);
    setWhiteCircleStyle(circleStyle);

    setIsPurpleCircleAnimating(true);

    // 300ms後に白円もアニメーション開始
    setTimeout(() => {
      setIsWhiteCircleAnimating(true);
    }, 300);
  };

  return (
    <>
      <div className="number-question">
        <div className="number-question--calc">
          <div className="number-question--calc__display">
            {Number(input).toLocaleString()}
          </div>
          {[...Array(10).keys()].map((i) => (
            <div
              className={`number-question--calc__btn-${i}`}
              onClick={() => handleBtnClick(i)}
              key={i}
            >
              {i}
            </div>
          ))}
          <div
            className={`number-question--calc__btn-00`}
            onClick={() => handleBtnClick('00')}
          >
            00
          </div>
          <div
            className={`number-question--calc__btn-back`}
            onClick={() => handleBtnClick('back')}
          >
            <FontAwesomeIcon icon={faBackspace} />
          </div>
          <div
            className={`number-question--calc__btn-clear`}
            onClick={() => handleBtnClick('clear')}
          >
            C
          </div>
        </div>

        <div className="number-question--button-wrapper">
          <RaisedButton color="--purple500" onClick={handleSubmit}>
            次の質問へ
          </RaisedButton>
        </div>
      </div>
      <CircleAnimator
        className="number-question--purple-circle"
        color="--purple500"
        animate={isPurpleCircleAnimating}
        style={purpleCircleStyle}
      />
      <CircleAnimator
        className="number-question--white-circle"
        color="#fff"
        onAnimated={() => onSubmit(input)}
        animate={isWhiteCircleAnimating}
        style={whiteCircleStyle}
      />
    </>
  );
};
