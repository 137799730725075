import { logEvent } from '@firebase/analytics';
import { analytics } from './firebase';

export const CustomEvent = {
  ANSWER_PUBLISHED_START: 'answer_published_start',
  ANSWER_PUBLISHED_EXIT: 'answer_published_exit',
  ANSWER_PUBLISHED_RESULT: 'answer_published_result',
  ANSWER_SUGGESTION_START: 'answer_suggestion_start',
  ANSWER_SUGGESTION_EXIT: 'answer_suggestion_exit',
  ANSWER_SUGGESTION_RESULT: 'answer_suggestion_result',
} as const;
type CustomEventType = typeof CustomEvent[keyof typeof CustomEvent];
interface CustomEventParams {
  answer_published_start: {
    type: 'SURVEY' | 'SUGGESTION';
    publishedId: string;
  };
  answer_published_exit: {
    type: 'SURVEY' | 'SUGGESTION';
    publishedId: string;
    progress: number;
  };
  answer_published_result: {
    type: 'SURVEY' | 'SUGGESTION';
    publishedId: string;
  };
  answer_suggestion_start: { workspaceId: string; suggestionId: string };
  answer_suggestion_exit: {
    workspaceId: string;
    suggestionId: string;
    progress: number;
  };
  answer_suggestion_result: { workspaceId: string; suggestionId: string };
}

export const logCustomEvent = <T extends CustomEventType>(
  eventName: T,
  eventParams: CustomEventParams[T],
): void => {
  logEvent(analytics, eventName as string, eventParams);
};
