import React from 'react';
import styled from 'styled-components';

import { generateButtonStyleVariables } from 'util/style';
import { colors } from 'util/theme';

interface Props {
  href: string;
  children?: React.ReactNode;
  className?: string;
}
export const ResultLink: React.VFC<Props> = ({ href, children, className }) => {
  const style = generateButtonStyleVariables(colors.primary[500]);

  return (
    <StyledAnchor
      className={className}
      href={href}
      target="_blank"
      rel="noopener"
      style={style}
    >
      {children}
    </StyledAnchor>
  );
};

const StyledAnchor = styled.a`
  display: block;

  width: 100%;
  padding: 1em 1.5em;
  border-radius: 1.5em;
  background: var(--color);
  box-shadow: 0 2px 6px 0 var(--shadow-color-1),
    0 2px 4px 0 var(--shadow-color-2);

  transition: 0.3s box-shadow ease-in-out;
  overflow: hidden;

  color: #fff;
  font-size: min(2.5vh, 4.5vw);
  line-height: 1em;
  font-weight: 500;
  text-align: center;
  white-space: pre;
  text-overflow: ellipsis;

  &:hover {
    box-shadow: 0 8px 12px -1px var(--shadow-color-1),
      0 4px 8px -1px var(--shadow-color-2);
  }
  &:focus {
    outline: none;
  }
`;
