import React, { useEffect, useRef } from 'react';
import './CompletedPage.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { BackToTopButton } from './BackToTopButton';

const TIMEOUT_DURATION = 10000;

interface Props {
  backToWaitingPage: () => void;
}
export const CompletedPage: React.VFC<Props> = ({ backToWaitingPage }) => {
  const timeout = useRef<number>();

  // 指定秒数経過後に強制遷移
  useEffect(() => {
    timeout.current = window.setTimeout(() => {
      backToWaitingPage();
    }, TIMEOUT_DURATION);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [backToWaitingPage]);

  return (
    <div className="completed">
      <div className="completed--spacer-large" />
      <h1 className="completed--message">ありがとうございました</h1>
      <div className="completed--spacer" />
      <FontAwesomeIcon className="completed--icon" icon={faCheckCircle} />
      <div className="completed--spacer-large" />

      <BackToTopButton onClick={backToWaitingPage} />
    </div>
  );
};
