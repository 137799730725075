import React from 'react';

export const linkifyMarkdown = (text: string): React.ReactNode[] => {
  const splittedText = text.split(/(\[.+\]\(.+\))/gi);
  return splittedText.map((part, idx) => {
    if (idx % 2 === 0) {
      return part;
    }
    const matchResult = part.match(/\[(.+)\]\((.+)\)/);
    if (matchResult == null) {
      return part;
    }
    const [, text, url] = matchResult;
    return (
      <a href={url} rel="noopener noreferrer" target="_blank" key={idx}>
        {text}
      </a>
    );
  });
};
