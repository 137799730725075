import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { generateButtonStyleVariables } from 'util/style';
import './CircleButton.scss';

const ANIMATION_DURATION = 600;

interface Props {
  color: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  onAnimated?: () => void;
  className?: string;
  style?: React.CSSProperties;
}
export const CircleButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    { color, onAnimated, children, disabled, onClick, className, style },
    ref,
  ) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [btnStyle, setBtnStyle] = useState<React.CSSProperties>();
    const btnRef = useRef<HTMLButtonElement>();
    const colorStyle = generateButtonStyleVariables(color);

    const onButtonClick = () => {
      if (disabled) return;

      onClick?.();

      if (isAnimating) {
        return;
      }

      const btn = btnRef.current;
      if (btn != null) {
        // 拡大率を計算しtransformする
        const bodyRect = document.body.getBoundingClientRect();
        const btnRect = btn.getBoundingClientRect();
        const btnCenterX = btnRect.x + btnRect.width / 2;
        const btnCenterY = btnRect.y + btnRect.height / 2;
        const maxX = Math.max(
          btnCenterX,
          Math.abs(bodyRect.width - btnCenterX),
        );
        const maxY = Math.max(
          btnCenterY,
          Math.abs(bodyRect.height - btnCenterY),
        );
        const maxRadius = Math.sqrt(maxX ** 2 + maxY ** 2);
        const ratio =
          maxRadius / Math.max(btn.clientWidth / 2, btn.clientHeight / 2);

        setIsAnimating(true);
        setBtnStyle({
          transform: `scale(${ratio})`,
        });
      }

      setTimeout(() => {
        onAnimated?.();
      }, ANIMATION_DURATION);
    };

    const attachRef = (el: HTMLButtonElement) => {
      btnRef.current = el;

      if (typeof ref === 'function') {
        ref(el);
      } else if (ref != null) {
        ref.current = el;
      }
    };

    return (
      <button
        className={classNames('circle-button', className, {
          animating: isAnimating,
        })}
        style={{ ...colorStyle, ...style, ...btnStyle }}
        onClick={onButtonClick}
        disabled={disabled}
        ref={attachRef}
      >
        {!isAnimating && children}
      </button>
    );
  },
);

CircleButton.displayName = 'CircleButton';
