import React, { useEffect, useRef, useState } from 'react';
import { Prompt } from 'react-router';
import './AnswerPage.scss';

import { SurveyAnswer } from 'util/api/survey';
import {
  InputQuestion,
  isQuestion,
  isResult,
  KirinResult,
  SelectQuestion,
} from 'util/question';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { IconButton } from '../common/form/IconButton';
import { AnswerQuestion } from './AnswerQuestion';
import { AnswerResult } from './AnswerResult';

export type AnswerValues = SurveyAnswer['values'];

interface Props {
  questions: (SelectQuestion | InputQuestion | KirinResult)[];
  onSendAnswer: (answers: AnswerValues) => void;
  onComplete: () => void;
  onExit: (progress: number) => void;
}
export const AnswerPage: React.VFC<Props> = ({
  questions,
  onSendAnswer,
  onComplete,
  onExit,
}) => {
  const [answers, setAnswers] = useState<AnswerValues>(
    new Array(questions.length).fill(null),
  );
  const [currentIdx, setCurrentIdx] = useState(0);
  const hiddenQuestions = useRef(new Set<number>());

  const currentQuestion = questions[currentIdx];
  const canGoPrev = currentIdx >= 0;
  const canGoNext =
    currentIdx < questions.length &&
    isQuestion(currentQuestion) &&
    !currentQuestion.required;

  useEffect(() => {
    const callback = () => {
      const answerCount = answers.reduce<number>(
        (prev, answer) => (answer != null ? prev + 1 : prev),
        0,
      );
      const progress = Math.min(
        1,
        answerCount / (questions.length - hiddenQuestions.current.size),
      );
      onExit(progress);
    };
    window.addEventListener('unload', callback);
    return () => {
      window.removeEventListener('unload', callback);
    };
  }, [answers, onExit, questions.length]);

  // 回答をステートにセットし、次の質問へ移動
  const onAnswer = (answer: number | string) => {
    // 回答をセット
    const updatedAnswers = [...answers];
    updatedAnswers[currentIdx] = answer;
    setAnswers(updatedAnswers);

    // 表示条件にマッチする質問を開放
    questions.forEach((question, i) => {
      // 表示条件が設定されていない質問は無条件で表示
      const condition = question.condition;
      if (!condition.length) {
        return;
      }

      let shouldShow = false;
      for (const cond of condition) {
        // 分岐質問の現在の回答が一致している場合は表示
        if (updatedAnswers[cond.question] === cond.option) {
          shouldShow = true;
        }
      }
      if (shouldShow) {
        hiddenQuestions.current.delete(i);
      } else {
        hiddenQuestions.current.add(i);
      }
    });

    // まだ質問が残っている場合は次の質問へ
    if (currentIdx + 1 < questions.length) {
      // 表示しない質問を飛ばす
      for (let i = currentIdx + 1; i < questions.length; i++) {
        if (!hiddenQuestions.current.has(i)) {
          setCurrentIdx(i);

          if (isResult(questions[i])) {
            onSendAnswer(updatedAnswers);
          }
          return;
        }
      }
    }

    // もう質問が残っていない場合と、質問が残っているが非表示にする場合は、
    // 全ての質問を終え、完了
    onSendAnswer(updatedAnswers);
    onComplete();
  };

  const onClickGoPrev = () => {
    if (!canGoPrev) {
      return;
    }

    // 条件によって表示されない質問を飛ばす
    for (let i = currentIdx - 1; i >= 0; i--) {
      if (!hiddenQuestions.current.has(i)) {
        setCurrentIdx(i);

        // 戻った先の質問以降の回答をリセット
        setAnswers((prev) => prev.map((v, j) => (j < i ? v : null)));
        return;
      }
    }
  };

  const onClickGoNext = () => {
    // 条件によって表示されない質問を飛ばす
    for (let i = currentIdx + 1; i < questions.length; i++) {
      if (!hiddenQuestions.current.has(i)) {
        setCurrentIdx(i);

        if (isResult(questions[i])) {
          onSendAnswer(answers);
        }
        return;
      }
    }

    // これ以降質問がないなら終了する
    onSendAnswer(answers);
    onComplete();
  };

  const backToTop = () => {
    onComplete();
  };

  return (
    <div className="answer">
      <Prompt
        when={answers.length > 0}
        message="回答が破棄されますが、よろしいですか？"
      />

      <div className="answer--white-cover" />
      {isQuestion(currentQuestion) && (
        <header className="answer--header">
          {canGoPrev ? (
            <IconButton title="ひとつ前の質問へ戻る" onClick={onClickGoPrev}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </IconButton>
          ) : (
            <div className="answer--header__spacer" />
          )}
          {canGoNext ? (
            <IconButton title="次の質問へ進む" onClick={onClickGoNext}>
              <FontAwesomeIcon icon={faAngleRight} />
            </IconButton>
          ) : (
            <div className="answer--header__spacer" />
          )}
        </header>
      )}

      {isQuestion(currentQuestion) ? (
        <AnswerQuestion
          question={currentQuestion}
          onAnswer={onAnswer}
          key={currentIdx}
        />
      ) : (
        <AnswerResult
          result={currentQuestion}
          onClickBackToTop={backToTop}
          key={currentIdx}
        />
      )}
    </div>
  );
};
