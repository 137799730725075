import React from 'react';
import classNames from 'classnames';
import './LoadingSpinner.scss';

interface Props {
  size?: string;
  color?: string;
  strokeWidth?: string;
  className?: string;
}
export const LoadingSpinner: React.VFC<Props> = ({
  size = '1em',
  color = '#fff',
  strokeWidth = '3px',
  className,
}) => {
  return (
    <svg
      viewBox="0 0 24 24"
      className={classNames('loading-spinner', className)}
      style={{ width: size, height: size }}
    >
      <circle
        className="loading-spinner--path"
        cx="12"
        cy="12"
        r="10"
        fill="none"
        strokeMiterlimit="10"
        style={{
          stroke: color,
          strokeWidth: strokeWidth,
        }}
      />
    </svg>
  );
};
