import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useUser } from './UserContext';

interface WorkspaceContextValue {
  currentWorkspaceId?: string;
}
interface WorkspaceDispatchContextValue {
  switchWorkspace: (workspaceId: string) => void;
}

export const WorkspaceContext = createContext<WorkspaceContextValue>({
  currentWorkspaceId: undefined,
});
export const WorkspaceDispatchContext =
  createContext<WorkspaceDispatchContextValue>({
    switchWorkspace: () => void 0,
  });

interface Props {
  children: React.ReactNode;
}
export const WorkspaceContextProvider: React.VFC<Props> = ({ children }) => {
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState<string>();
  const { user } = useUser();

  useEffect(() => {
    setCurrentWorkspaceId((prev) => {
      if (prev != null) {
        return prev;
      }
      return user?.privateWorkspaceId;
    });
  }, [user?.privateWorkspaceId]);

  const switchWorkspace = useCallback((workspaceId: string) => {
    setCurrentWorkspaceId(workspaceId);
  }, []);

  return (
    <WorkspaceContext.Provider
      value={useMemo(() => ({ currentWorkspaceId }), [currentWorkspaceId])}
    >
      <WorkspaceDispatchContext.Provider
        value={useMemo(() => ({ switchWorkspace }), [switchWorkspace])}
      >
        {children}
      </WorkspaceDispatchContext.Provider>
    </WorkspaceContext.Provider>
  );
};

export const useWorkspace = (): WorkspaceContextValue => {
  return useContext(WorkspaceContext);
};

export const useWorkspaceDispatch = (): WorkspaceDispatchContextValue => {
  return useContext(WorkspaceDispatchContext);
};
