import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { useSnackBar } from 'contexts/SnackBarContext';
import { useApiErrorSnackBar } from 'hooks/useApiErrorSnackBar';
import { postAnswerForSurvey } from 'util/api/published';
import { Survey } from 'util/api/survey';
import { colors } from 'util/theme';

import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { WaitingPage } from '../../../components/answer/WaitingPage';
import {
  AnswerPage,
  AnswerValues,
} from '../../../components/answer/AnswerPage';
import { CompletedPage } from '../../../components/answer/CompletedPage';

type SurveyState = 'WAITING' | 'ANSWERING' | 'COMPLETED';

interface Props {
  publishedId: string;
  survey: Survey;
  isDemo: boolean;
}

export const SurveyAnswer: React.VFC<Props> = ({
  publishedId,
  survey,
  isDemo,
}) => {
  // アンケート回答ページは、一つのコンポーネントの中で「待機」「回答」「完了」の三画面を切り替える
  // （URLを直接遷移してスキップされるのを防止するため、ステートをメモリで管理）
  const [pageState, setPageState] = useState<SurveyState>('WAITING');
  const [isSending, setIsSending] = useState(false);
  const [showSnackBar] = useSnackBar();
  const [showApiErrorSnackBar] = useApiErrorSnackBar();

  // 回答を送信し、完了画面へ遷移
  // ステート更新前に実行されるため、answersは新しいものを受け取る
  const sendAnswer = async (answers: AnswerValues) => {
    if (survey == null) {
      return;
    }
    // デモなら送信しない
    if (isDemo) {
      return;
    }
    setIsSending(true);

    try {
      await postAnswerForSurvey(publishedId, answers);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 400) {
          showSnackBar(
            'ERROR',
            '回答の送信中にエラーが発生しました。入力内容が正しいかどうかご確認の上、再度ご回答ください。',
          );
        } else if (err.response?.status === 500) {
          showSnackBar(
            'ERROR',
            '回答の登録中にサーバーでエラーが発生しました。運営者にお問い合わせください。',
          );
        } else {
          showApiErrorSnackBar(err);
        }
      } else {
        showSnackBar('ERROR', '回答の送信中にエラーが発生しました。');
      }
    }
    setIsSending(false);
  };

  // 回答画面へ移動
  const moveToAnswerPage = () => {
    setPageState('ANSWERING');
  };

  // 回答画面へ移動
  const moveToCompletePage = () => {
    setPageState('COMPLETED');
  };

  // 待機画面に戻る
  const backToWaitingPage = () => {
    setPageState('WAITING');
  };

  if (pageState === 'WAITING') {
    return (
      <WaitingPage
        title={survey.title}
        detail={survey.detail}
        onStart={moveToAnswerPage}
      />
    );
  } else if (pageState === 'ANSWERING') {
    return (
      <AnswerPage
        questions={survey.questions}
        onSendAnswer={sendAnswer}
        onComplete={moveToCompletePage}
        onExit={() => void 0}
      />
    );
  } else {
    if (isSending) {
      return (
        <SendingOverlay>
          <LoadingIndicator size="10vh" color={colors.primary[500]} />
        </SendingOverlay>
      );
    }
    return <CompletedPage backToWaitingPage={backToWaitingPage} />;
  }
};

const SendingOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  background: #fff;
`;
