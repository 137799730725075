import { initializeApp } from '@firebase/app';
import { connectAuthEmulator, getAuth } from '@firebase/auth';
import { connectFirestoreEmulator, getFirestore } from '@firebase/firestore';
import { getAnalytics } from '@firebase/analytics';

// Change app config depends on node env
const firebaseConfig =
  process.env.NODE_ENV === 'production'
    ? {
        apiKey: 'AIzaSyDYLdCro8uJqNzOg3FktYeVam2GUo8PlyI',
        authDomain: 'prane-balloon.firebaseapp.com',
        projectId: 'prane-balloon',
        storageBucket: 'prane-balloon.appspot.com',
        messagingSenderId: '845818550172',
        appId: '1:845818550172:web:c7012ddba318a4eb22da59',
        measurementId: 'G-WKH7M0X5ZP',
      }
    : {
        apiKey: 'AIzaSyDYLdCro8uJqNzOg3FktYeVam2GUo8PlyI',
        authDomain: 'prane-balloon.firebaseapp.com',
        projectId: 'prane-balloon',
        storageBucket: 'prane-balloon.appspot.com',
        messagingSenderId: '845818550172',
        appId: '1:845818550172:web:8e6981cceaa1515c22da59',
        measurementId: 'G-T07EY14PBZ',
      };
initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore();
export const analytics = getAnalytics();

// In development or test mode, use emulators
if (process.env.NODE_ENV !== 'production') {
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
  connectFirestoreEmulator(db, 'http://localhost', 8080);
}
