import React, { useMemo } from 'react';
import styled from 'styled-components';
import { linkifyMarkdown } from 'util/string';

import { KirinResult } from 'util/question';
import { BackToTopButton } from './BackToTopButton';
import { ResultLink } from './ResultLink';

interface Props {
  result: KirinResult;
  onClickBackToTop: () => void;
}
export const AnswerResult: React.VFC<Props> = ({
  result,
  onClickBackToTop,
}) => {
  const linkifiedDetail = useMemo(() => {
    return linkifyMarkdown(result.detail);
  }, [result.detail]);

  return (
    <Container>
      <WhiteCover />

      <UpperArea>
        <div style={{ flex: 3 }} />
        <div style={{ flex: '0 0 auto' }}>
          <ResultSubText>{result.resultSubText}</ResultSubText>
          <ResultText>{result.resultText}</ResultText>
        </div>
        <div style={{ flex: 2 }} />
      </UpperArea>

      <Sheet>
        <ResultDetailArea>
          <ResultDetailAreaInner>
            <ResultDetailItemArea>
              <ResultImage src={result.imageUrl} alt={result.title} />
              <ResultDetailItemTextArea>
                <ResultSubTitle>{result.subTitle}</ResultSubTitle>
                <ResultTitle>{result.title}</ResultTitle>
                <ResultDetail>{linkifiedDetail}</ResultDetail>
              </ResultDetailItemTextArea>
            </ResultDetailItemArea>
            <StyledResultLink href={result.buttonUrl}>
              {result.buttonText}
            </StyledResultLink>
          </ResultDetailAreaInner>
        </ResultDetailArea>
      </Sheet>

      <BackToTopButton onClick={onClickBackToTop} />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: 30% 70%;
  height: 100%;
`;

const WhiteCover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: #fff;

  animation: 0.5s ${(p) => p.theme.easings.easeOut} forwards
    survey-unveil-animation;
  pointer-events: none;
`;

const UpperArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  padding: 2vh;

  animation: 1s ${(p) => p.theme.easings.easeOut} backwards
    survey-text-animation;
`;

const ResultSubText = styled.h1`
  color: #fff;
  font-size: min(2vh, 4vw);
  line-height: 1.375em;
  font-weight: 500;
  text-align: center;
`;

const ResultText = styled.h1`
  margin-top: 0.5vh;

  color: #fff;
  font-size: min(3.5vh, 6vw);
  line-height: 1.375em;
  font-weight: 500;
  text-align: center;
`;

const Sheet = styled.div`
  height: 100%;
  border-radius: 6vh 6vh 0 0;
  background: #fff;

  animation: 1s ${(p) => p.theme.easings.easeOut} 1s backwards
    survey-footer-animation;
`;

const ResultDetailArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 4.5vh min(4.5vh, 5vw) 9vh;
`;

const ResultDetailAreaInner = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  max-width: 100vh;
  width: 100%;
  max-height: 100%;
`;

const ResultDetailItemArea = styled.div`
  display: grid;
  grid-template-columns: 20% 70%;
  grid-template-rows: 100%;
  grid-gap: 10%;
  overflow-y: auto;

  @media (max-width: ${(p) => p.theme.breaks.sm}) {
    grid-template-columns: auto;
    grid-template-rows: 15vh auto;
    grid-gap: 3vh;
  }
`;

const ResultImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ResultDetailItemTextArea = styled.div`
  overflow-y: auto;
`;

const ResultSubTitle = styled.div`
  color: ${(p) => p.theme.colors.text.base};
  font-size: min(2vh, 3.5vw);
  font-weight: bold;
`;

const ResultTitle = styled.div`
  color: ${(p) => p.theme.colors.text.base};
  font-size: min(3vh, 5vw);
  font-weight: bold;
`;

const ResultDetail = styled.div`
  margin-top: 2vh;
  color: ${(p) => p.theme.colors.text.light};
  font-size: min(2vh, 3.5vw);
  white-space: pre-line;
  word-wrap: break-word;
`;

const StyledResultLink = styled(ResultLink)`
  margin-top: min(4.5vh, 8vw);
`;
