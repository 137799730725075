import { AxiosError } from 'axios';
import { useCallback } from 'react';
import useSWR from 'swr';
import { requestGet, requestPost } from 'util/apiAxios';
import {
  convertResponseSuggestion,
  Suggestion,
  SuggestionAnswer,
  SuggestionResponse,
} from './suggestion';
import {
  convertResponseSurvey,
  Survey,
  SurveyAnswer,
  SurveyResponse,
} from './survey';

type SurveyWithType = Survey & { type: 'SURVEY' };
type SuggestionWithType = Suggestion & { type: 'SUGGESTION' };

type PublishedResponse =
  | (SurveyResponse & { type: 'SURVEY' })
  | (SuggestionResponse & { type: 'SUGGESTION' });

export const getPublished = async (
  publishedId: string,
): Promise<SurveyWithType | SuggestionWithType> => {
  const { data } = await requestGet<PublishedResponse>(
    `/api/published/${publishedId}`,
  );
  if (data.type === 'SURVEY') {
    return { ...convertResponseSurvey(data), type: 'SURVEY' };
  } else {
    return { ...convertResponseSuggestion(data), type: 'SUGGESTION' };
  }
};

type SurveyAnswerRequest = Pick<SurveyAnswer, 'values'>;
export const postAnswerForSurvey = async (
  publishedId: string,
  values: SurveyAnswer['values'],
) => {
  return requestPost<unknown, SurveyAnswerRequest>(
    `/api/published/${publishedId}/answer`,
    { values },
  );
};

type SuggestionAnswerRequest = Pick<SuggestionAnswer, 'values'>;
export const postAnswerForSuggestion = async (
  publishedId: string,
  values: SuggestionAnswer['values'],
) => {
  console.log('here');
  return requestPost<unknown, SuggestionAnswerRequest>(
    `/api/published/${publishedId}/answer`,
    { values },
  );
};

export const usePublished = (publishedId: string) => {
  const { data, error, mutate } = useSWR<
    SurveyWithType | SuggestionWithType,
    AxiosError
  >(`/api/published/${publishedId}`, () => getPublished(publishedId), {
    revalidateOnFocus: false,
  });
  const refetchPublished = useCallback(() => {
    mutate();
  }, [mutate]);
  return {
    published: data,
    error,
    isLoading: !data && !error,
    refetchPublished,
  } as const;
};
