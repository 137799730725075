import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';

import 'focus-visible';
import 'ress';
import './_variables.scss';

import * as theme from 'util/theme';
import { store } from 'redux/store';
import { UserContextProvider } from './contexts/UserContext';

import { Router } from './router/Router';
import { WorkspaceContextProvider } from 'contexts/WorkspaceContext';
import { RootErrorBoundary } from 'components/common/RootErrorBoundary';
import { SnackBarContextProvider } from 'contexts/SnackBarContext';

const history = createBrowserHistory();

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'production'
      ? 'https://38a4124775924c6f94dda156aa95e3ba@o400318.ingest.sentry.io/5994948'
      : undefined,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
});

export const App: React.VFC = () => {
  return (
    <ThemeProvider theme={theme}>
      <RootErrorBoundary>
        <Provider store={store}>
          <SnackBarContextProvider>
            <UserContextProvider>
              <WorkspaceContextProvider>
                <BrowserRouter>
                  <Route component={Router} />
                </BrowserRouter>
              </WorkspaceContextProvider>
            </UserContextProvider>
          </SnackBarContextProvider>
        </Provider>
      </RootErrorBoundary>
    </ThemeProvider>
  );
};
