import { useCallback, useState } from 'react';

export const useThrowError = (): ((error: unknown) => void) => {
  const [, set] = useState();
  const throwError = useCallback((error: unknown) => {
    set(() => {
      throw error;
    });
  }, []);
  return throwError;
};
