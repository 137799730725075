import React from 'react';
import './LoadingIndicator.scss';

import { LoadingSpinner } from './icons/LoadingSpinner';

interface Props {
  size?: string;
  color?: string;
}
export const LoadingIndicator: React.VFC<Props> = ({ size, color }) => (
  <div className="loading-indicator" style={{ padding: size || '2em' }}>
    <LoadingSpinner size={size || '2em'} color={color || 'var(--gray600)'} />
  </div>
);
