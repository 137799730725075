import React, { useState, useRef, useEffect, createRef } from 'react';
import classNames from 'classnames';
import './BalloonOptions.scss';

import { Option } from 'util/question';
import { CircleButton } from './CircleButton';
import { CircleAnimator } from './CircleAnimator';

interface Props {
  options: Option[];
  onSubmit: (index: number) => void;
}
export const BalloonOptions: React.VFC<Props> = ({ options, onSubmit }) => {
  // ひとつの選択肢がクリックされたら他の選択肢はクリックできないようにする
  const [isSelected, setIsSelected] = useState(false);

  const [isCircleAnimating, setIsCircleAnimating] = useState(false);
  const [circleStyle, setCircleStyle] = useState<React.CSSProperties>();

  const [optionRefs, setOptionRefs] = useState<
    React.RefObject<HTMLButtonElement>[]
  >([]);
  const selectedOption = useRef<number>(0);

  useEffect(() => {
    setOptionRefs((prev) =>
      Array(options.length)
        .fill(undefined)
        .map((_, idx) => prev[idx] || createRef()),
    );
  }, [options]);

  // グリッドの列数を決定
  let columnNum;
  if (options.length <= 3) {
    columnNum = options.length;
  } else if (options.length === 4) {
    columnNum = 2;
  } else {
    columnNum = 'auto-fit';
  }
  const gridStyle = {
    gridTemplateColumns: `repeat(${columnNum}, 19vh)`,
  };

  const onOptionClicked = (idx: number) => {
    if (isSelected) return;
    setIsSelected(true);

    selectedOption.current = idx;

    const option = optionRefs[idx].current;
    if (option != null) {
      // 白円の位置、サイズを調整
      const whiteCircleStyle: React.CSSProperties = {};
      const btnRect = option.getBoundingClientRect();

      whiteCircleStyle.width = `${btnRect.width}px`;
      whiteCircleStyle.height = `${btnRect.height}px`;
      whiteCircleStyle.left = `${btnRect.x}px`;
      whiteCircleStyle.top = `${btnRect.y}px`;

      setCircleStyle(whiteCircleStyle);
    }

    // 300ms後に白円もアニメーション開始
    setTimeout(() => {
      setIsCircleAnimating(true);
    }, 300);
  };

  return (
    <>
      <div className="balloon-options" style={gridStyle}>
        {options.map((option, idx) => (
          <CircleButton
            className={classNames('balloon-options--option', {
              'long-text': option.text.length >= 4,
            })}
            color={option.color}
            onClick={() => onOptionClicked(idx)}
            disabled={isSelected}
            key={option.key}
            ref={optionRefs[idx]}
          >
            {option.text}
          </CircleButton>
        ))}
      </div>
      <CircleAnimator
        className="balloon-options--white-circle"
        color="#fff"
        onAnimated={() => onSubmit(selectedOption.current)}
        animate={isCircleAnimating}
        style={circleStyle}
      />
    </>
  );
};

BalloonOptions.propTypes = {};
