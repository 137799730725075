import { captureException } from '@sentry/react';

export const reportError = (error: unknown): void => {
  captureException(error);
};

export class CustomApiError extends Error {
  constructor(e?: string) {
    super(e);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
